<template>
  <div>
    <div class="text">湖南子书信息咨询有限公司大连庄河分公司</div>
    <div class="head">
      <div>网站首页</div>
      <div><a href="#AboutUs">关于我们</a></div>
      <div><a href="#ProductDisplay">产品展示</a></div>
      <div><a href="#CasePresentation">案例展示</a></div>
      <div><a href="#contactus">联系我们</a></div>
    </div>
    <img src="@/assets/loge1.jpg" alt="" width="100%" height="600px" />
    <div class="cont">
      <div class="box">
        <div class="box1" id="ProductDisplay">
          <div style="margin-bottom: 20px">产品展示</div>
          <div style="margin-bottom: 20px">ProductDisplay</div>
          <div class="box2">
            <div class="text1">网络服务-网站建设</div>
            <div class="text1">职业培训</div>
            <div class="text1">教育培训</div>
            <div class="text1">软件-实用工具</div>
            <div class="text1">服装鞋帽-钟表</div>
            <div class="text1">贷款平台</div>
            <div class="text1">珠宝首饰</div>
            <div class="text1">生活服务-餐饮</div>
            <div class="text1">服装鞋帽-钟表</div>
            <div class="text1">电子电工-电子器件</div>
            <div class="text1">软件-实用工具</div>
            <div class="text1">机械设备-通用机械设备</div>
            <div class="her">
              <div class="tesst">- 全国热选 -</div>
              <div>400-6188521</div>
            </div>
          </div>
        </div>
        <div class="guythb">
          <div class="ftrtfg">
            <img
              src="@/assets/img1.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img3.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img2.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img1.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img2.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img2.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img3.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img1.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
        </div>
      </div>
      <div id="AboutUs">
        <div class="guanyuwomen">关于我们</div>
        <div class="xian">
          <div class="xian1"></div>
          <div class="xian2"></div>
        </div>
        <div class="cian"></div>
        <div class="sththh">
          <div class="dhnnh">
            十多年间，本着“用心服务助力客户”的世纪，已在相关领域为超过30000+家的中小企业经营和家庭的消费提供了专业dk咨询服务，帮助其解决资金需求公司简介：成立于2042年，是一家自营模式的金融公司，提供中小企业经营和个人消费、信贷咨询领域的全品类金融服务。湖南子书信息咨询有限公司大连庄河分公司成立于2024年03月27日，注册地位于辽宁省大连市庄河市昌盛街道庄打路199号3楼325-03室，负责人为秦衡。经营范围包括一般项目：凭总公司授权开展经营活动。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）
            。经营范围包括其他组织管理服务；企业管理咨询服务；企业财务咨询服务（不含金融、证券、期货咨询）；会计咨询；企业会计报表审计；代理记账服务；企业财务危机化解；商务信息咨询；税收策划；涉税服务；税务咨询；专利代理服务；商标代理；版权服务；著作权代理；知识产权代理；知识产权运营管理；企业管理服务；人力资源服务外包；办理企业合并、分立、清算事宜中的审计；法律咨询；劳动保障事务咨询服务；票据咨询服务；贸易咨询服务；企业形象策划服务；文化艺术咨询服务；市场营销策划服务；公共关系服务；策划创意服务；企业营销策划；代办按揭服务；代办营业执照；代办贷款服务；为政府部门提供社保和医保管理服务；市场管理服务；会议、展览及相关服务；品牌策划咨询服务；广告国内外代理服务；文化活动的组织与策划；导向标识设计；商业活动的策划；图文制作；会展业的经营和服务；物···
          </div>
          <div>
            <img src="@/assets/img3.jpg" alt="" srcset="" />
          </div>
        </div>
        <div class="lianxigeng">联系电话：400-6188521</div>
        <div>-----了解更多</div>
      </div>
      <div id="CasePresentation">
        <div class="guanyuwomen" style="margin-top: 50px">
          合作众多客户 服务至上
        </div>
        <div class="xian">
          <div class="xian1"></div>
          <div class="xian2"></div>
        </div>
        <div class="cian"></div>
        <div class="guythb111">
          <div class="ftrtfg">
            <img
              src="@/assets/img1.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img3.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img2.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img1.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img2.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img2.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img3.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
          <div class="ftrtfg">
            <img
              src="@/assets/img1.jpg"
              alt=""
              srcset=""
              width="280px"
              style="border-radius: 4px"
            />
            <div>1234565</div>
          </div>
        </div>
      </div>
    </div>
    <div class="head111" id="contactus">
      <div style="text-align: center">
        版权所有：湖南子书信息咨询有限公司大连庄河分公司 | 辽ICP备2024030516号-1
        | 地址：辽宁省大连市庄河市昌盛街道庄打路199号3楼325-03室
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
a:link {
  text-decoration: none;
  color: #ffff;
}
a:visited {
  text-decoration: none;
  color: #ffff;
}
a:hover {
  text-decoration: none;
  color: #ffff;
}
a:active {
  text-decoration: none;
  color: #ffff;
}
* {
  padding: 0;
  margin: 0;
}
.head {
  padding-left: 200px;
  padding-right: 200px;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  color: #ffff;
  font-size: 18px;
  line-height: 80px;
  font-weight: bold;
  align-content: center;
  background-color: #2a364f;
  cursor: pointer;
}
.head111 {
  margin-top: 50px;
  /* padding-left: 500px;
  padding-right: 500px; */
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  /* display: flex;
  justify-content: space-between; */
  color: #ffff;
  font-size: 16px;
  line-height: 80px;
  align-content: center;
  background-color: #2a364f;
  cursor: pointer;
}
.text {
  padding-left: 50px;
  font-size: 32px;
  color: #117cc0;
  font-family: "微软雅黑";
  padding: 50px;
}
.cont {
  width: 1200px;
  margin: 0 auto;
}
.box {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.box1 {
  font-size: 20px;
  width: 300px;
  background-color: #2a364f;
  padding: 20px;
  color: #ffffff;
  font-weight: bold;
  margin-right: 50px;
}
.box2 {
  background-color: #ffffff;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.text1 {
  font-size: 14px;
  font-weight: 100;
  padding: 15px;
  background-color: rgb(14, 74, 77);
  border-radius: 50px;
  margin-bottom: 10px;
  cursor: pointer;
}
.her {
  padding: 20px;
  background-color: rgba(24, 37, 63, 0.904);
  text-align: center;
}
.tesst {
  font-weight: 100;
  margin-bottom: 10px;
  font-size: 14px;
  color: #ffffff;
}
.guythb {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px 50px;
}
.guythb111 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 50px 50px;
}
.ftrtfg {
  text-align: center;
}
.guanyuwomen {
  font-size: 30px;
  text-align: center;
  margin-top: 50px;

  color: #333333;
}
.sththh {
  display: flex;
  justify-content: space-between;
}
.dhnnh {
  font-size: 15px;
  line-height: 34px;
  color: #333333;
  margin-right: 40px;
}
.lianxigeng {
  margin-top: 60px;
  color: #666666;
  font-size: 14px;
  margin-bottom: 30px;
}
.xian {
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin: 5px auto 0px;
  height: 3px;
}
.xian1 {
  width: 50px;
  background: rgb(42, 128, 226);
  height: 100%;
}
.xian2 {
  width: 50px;
  background: rgb(18, 67, 202);
  height: 100%;
}
.cian {
  width: 200px;
  margin: 0 auto 50px;
  height: 1px;
  background-color: #666666;
}
</style>
